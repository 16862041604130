define('sirvo-web/controllers/inventario/articulos/record', ['exports', 'servir-ember-utilities/controllers/abstract-module-record'], function (exports, _abstractModuleRecord) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractModuleRecord.default.extend({
    indexRoute: null,

    actions: {
      onCancel() {
        this.record.rollbackAttributes();

        this.transitionToRoute(this.get('indexRoute'));
      }
    }
  });
});