define('sirvo-web/mirage/config', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */

    this.urlPrefix = 'http://sirvo.local'; // make this `http://localhost:8080`, for example, if your API is on a different server
    this.namespace = '/api'; // make this `/api`, for example, if your API is namespaced
    this.timing = 400; // delay for each request, automatically set to 0 during testing

    this.post('/login_check', (schema, request) => {
      let attrs = JSON.parse(request.requestBody);

      if (attrs.username !== 'consulte' || attrs.password !== 'password') {
        return new _emberCliMirage.Response(401, {}, {
          code: 401,
          message: 'Invalid credentials.'
        });
      }

      schema.users.create(attrs);

      return new _emberCliMirage.Response(200, {
        'Content-Type': 'application/json'
      }, {
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1OTQwNzg5NDcsImV4cCI6MTU5NDA5MzM0Nywicm9sZXMiOltdLCJ1c2VybmFtZSI6IkNPTlNVTFRFICAgICAgICAifQ.U50VVPBY55CxQhcUafMuNj8fwUOYk9pG3PDCh_NI-gWpPty9JIozYD7TbMss74MsEFy54W0SSz9-SeeLKSkmtsVKQY1JHdMxQsihI7As5Vc2cNfLgz81sLKcKH01g7rDotyi1a0eMsR4nAxGYb6KtlgPocizj5Ppj-w749wB3F6gEdF_tvUNRFuwdKCEjhVUYZyVdZWGkRqiI49wn_2617wcvuk3C7lZQifad1yI3wGZrKsHMIrgGzuakgYu-vnUPRuyQJaAcDok39XA2L66hKWnoCmguYfYXnr70xT8vjCABAEQJYq50DoEJngC2Lv7Zz_KCbt5xuU8b9hm2ppNp_7i_rnOujcSoQSWsXeh7zPIbfG-hWy2JrbOAb76_Dd9GzwhzYyMYM2O6aPFYxiwDjR2r9HqaSfu30LjdWCwIN0PjMm_I3avW-t3RRvQtgToJ1yKfnod45UQ-WW7nRIcW54btkpoEtropxKOpM_0KhnPclkuOFGksXa-X_iXKW0ORusaj6D3OWsmQvyeGCiGeGnCoqedLjohXy2O4UuxQO3hIzDbA-wIGIzawMDGinY2qvJrNx6K5kux0OHkaMyU-QiTezuInGnahbTXDKO2aKBKq4aaHup0_k7pjuCzeec-qktDJcLKfzi8z0WT_AWr7-MnoVs-Crrtzrte32UJkpE',
        refresh_token: 'ce85bb546c239a99283d9d43d4543aab424e22bbec58c97b7c000504cc5e504336b0c13ea5686d55ddd45c85ea3bac8683719ef42856c2983d2b81d7e51eb9dd'
      });
    });

    this.get('/currentUser', schema => {
      schema.users.findBy({
        username: 'consulte'
      });

      return null;
    });

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       https://www.ember-cli-mirage.com/docs/route-handlers/shorthands
    */
  };
});