define('sirvo-web/routes/dashboard/arrendamiento', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    request: Ember.inject.service(),

    processor: Ember.inject.service('data-processor'),

    model() {
      return Ember.RSVP.hash({
        arrendamientosData: this.request.get('dashboard/arrendamiento/empresas'),
        localesData: this.request.get('dashboard/arrendamiento/locales')
      }).then(({ arrendamientosData, localesData }) => {
        let modelData = Ember.merge(this.processor.prepareArrendamientosData(arrendamientosData), this.processor.prepareLocalesData(localesData));

        return Ember.RSVP.resolve(modelData);
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('currentYear', new Date().getFullYear());
    }
  });
});