define('sirvo-web/routes/logout', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-local-storage'], function (exports, _authenticatedRouteMixin, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    empresaActual: (0, _emberLocalStorage.storageFor)('empresa'),

    model() {
      return this.session.invalidate().then(() => {
        this.empresaActual.clear();

        this.transitionTo('login');
      });
    }
  });
});