define('sirvo-web/components/tables/cell/accounting-amount', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    table: null,
    row: null,
    column: null,

    currency: Ember.computed.readOnly('column.emberIntlCurrency')
  });
});