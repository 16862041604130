define('sirvo-web/models/grudirectorio', ['exports', '@ember-data/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    coddirectorio: (0, _model.attr)('string'),
    codgrupo: (0, _model.attr)('string'),
    iddirectorio: (0, _model.attr)('number'),
    codaca: (0, _model.attr)('string'),
    nombredirectorio: (0, _model.attr)('string'),
    razonsocial: (0, _model.attr)('string'),
    nit: (0, _model.attr)('string'),
    id_interno: (0, _model.attr)('string'),
    no_dpi: (0, _model.attr)('string'),
    sucursal_mb: (0, _model.attr)('number'),
    persona_individual_juridica: (0, _model.attr)('number'),
    esextranjero: (0, _model.attr)('number'),
    esagenteretiva: (0, _model.attr)('number'),
    pagaretiva: (0, _model.attr)('number'),
    codretiva: (0, _model.attr)('string'),
    esagenteretisr: (0, _model.attr)('number'),
    pagaretisr: (0, _model.attr)('number'),
    codretisr: (0, _model.attr)('string'),
    usaexencioniva: (0, _model.attr)('number'),
    noregistroiva: (0, _model.attr)('string'),
    tipo_iva_directorio: (0, _model.attr)('string'),
    giroempresa: (0, _model.attr)('string'),
    tipocontri: (0, _model.attr)('string'),
    catecontri: (0, _model.attr)('string'),
    consumidorfinal: (0, _model.attr)('number'),
    fechacreacion: (0, _model.attr)('zero-date'),
    es_inactivo: (0, _model.attr)('number'),
    fechabaja: (0, _model.attr)('zero-date'),
    coduser_inactivo: (0, _model.attr)('string'),
    dias_cr_cxp: (0, _model.attr)('number'),
    dias_cr_cxc: (0, _model.attr)('number'),
    tiene_credito: (0, _model.attr)('boolean'),
    limite_cr_cxc: (0, _model.attr)('number'),
    lista_precios: (0, _model.attr)('number'),
    usavendedor: (0, _model.attr)('number'),
    codvendedor: (0, _model.attr)('string'),
    nomvendedor: (0, _model.attr)('string'),
    codruta: (0, _model.attr)('string'),
    codruta_cobro: (0, _model.attr)('string'),
    usa_sucursales: (0, _model.attr)('number'),
    observa: (0, _model.attr)('string'),
    direccion: (0, _model.attr)('string'),
    direccion2: (0, _model.attr)('string'),
    codigopostal: (0, _model.attr)('string'),
    codpais: (0, _model.attr)('string'),
    coddepto: (0, _model.attr)('string'),
    codzona: (0, _model.attr)('string'),
    codmuni: (0, _model.attr)('string'),
    telefonooficina: (0, _model.attr)('string'),
    telefonooficina2: (0, _model.attr)('string'),
    telefonocasa: (0, _model.attr)('string'),
    telefonomovil: (0, _model.attr)('string'),
    telefonobeeper: (0, _model.attr)('string'),
    telefonofax: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    email2: (0, _model.attr)('string'),
    email3: (0, _model.attr)('string'),
    email_publicidad: (0, _model.attr)('string'),
    webpage: (0, _model.attr)('string'),
    foto: (0, _model.attr)('string'),
    cumple_dia: (0, _model.attr)('number'),
    cumple_mes: (0, _model.attr)('number'),
    codsolicitud: (0, _model.attr)('string'),
    fecha_autoriza_solicitud: (0, _model.attr)('zero-date'),
    coduser_autoriza_solicitud: (0, _model.attr)('string'),
    documentado: (0, _model.attr)('boolean'),
    enletras_en_ingles: (0, _model.attr)('boolean'),
    usa_tdc_especial: (0, _model.attr)('boolean'),
    origen: (0, _model.attr)('string'),
    fac_nombre: (0, _model.attr)('string'),
    fac_nit: (0, _model.attr)('string'),
    fac_direccion: (0, _model.attr)('string'),
    chq_nombre: (0, _model.attr)('string'),
    pi_profesion: (0, _model.attr)('string'),
    pi_grupo: (0, _model.attr)('string'),
    pi_categoria: (0, _model.attr)('string'),
    pi_relacion: (0, _model.attr)('string'),
    pi_empresa_labora: (0, _model.attr)('string'),
    pi_empresa_labora_direccion: (0, _model.attr)('string'),
    pi_estatus_cuenta: (0, _model.attr)('string'),
    pi_locker_valor: (0, _model.attr)('number'),
    pi_cuota: (0, _model.attr)('number'),
    pi_fecha_ingreso: (0, _model.attr)('zero-date'),
    pi_fecha_nacimiento: (0, _model.attr)('zero-date'),
    pi_estado_civil: (0, _model.attr)('string'),
    pi_contacto: (0, _model.attr)('string'),
    pi_contacto_telefono: (0, _model.attr)('string'),
    pi_observaciones_adicionales: (0, _model.attr)('string'),
    tarj_credito_numero: (0, _model.attr)('string'),
    tarj_credito_vence: (0, _model.attr)('string'),
    tarj_credito_cobro_recurrente: (0, _model.attr)('boolean'),
    tarj_credito_monto_max_debito: (0, _model.attr)('number'),
    tarj_credito_fecha_aplica: (0, _model.attr)('zero-date'),
    tarj_credito_email_notif: (0, _model.attr)('string'),
    facebook: (0, _model.attr)('string'),
    whatsapp_numero: (0, _model.attr)('string'),
    cliente_tipo: (0, _model.attr)('string'),
    codigo_tributario: (0, _model.attr)('string'),
    codigo_cai: (0, _model.attr)('string'),
    fecha_actualizacion: (0, _model.attr)('zero-date'),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('zero-date'),
    codusr: (0, _model.attr)('string'),
    crea_estacion: (0, _model.attr)('string'),
    stausr: (0, _model.attr)('string'),
    crea_fecha: (0, _model.attr)('zero-date'),
    crea_usuario: (0, _model.attr)('string')
  });
});