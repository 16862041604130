define('sirvo-web/models/user', ['exports', '@ember-data/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    coduser: (0, _model.attr)('string'),
    nombre: (0, _model.attr)('string')
  });
});