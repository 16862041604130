define('sirvo-web/models/grudirectoriocontactos', ['exports', '@ember-data/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    coddirectorio: (0, _model.attr)('string'),

    codgrupo: (0, _model.attr)('string'),

    codcontacto: (0, _model.attr)('string'),

    nombre: (0, _model.attr)('string'),

    vocativo: (0, _model.attr)('string'),

    cargo: (0, _model.attr)('string'),

    departamento: (0, _model.attr)('string'),

    saludo: (0, _model.attr)('string'),

    direccion: (0, _model.attr)('string'),

    telefonooficina: (0, _model.attr)('string'),

    telefonomovil: (0, _model.attr)('string'),

    telefonofax: (0, _model.attr)('string'),

    telefonoparticular: (0, _model.attr)('string'),

    email: (0, _model.attr)('string'),

    webpage: (0, _model.attr)('string'),

    chat: (0, _model.attr)('string'),

    foto: (0, _model.attr)('string'),

    observa: (0, _model.attr)('string'),

    creusr: (0, _model.attr)('date'),

    fecusr: (0, _model.attr)('string'),

    codusr: (0, _model.attr)('string'),

    stausr: (0, _model.attr)('string'),

    creaFecha: (0, _model.attr)('date'),

    creaUsuario: (0, _model.attr)('string'),

    creaEstacion: (0, _model.attr)('string'),

    contactoFace: (0, _model.attr)('boolean')

  });
});