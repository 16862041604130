define('sirvo-web/routes/dashboard/index', ['exports', 'sirvo-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    toast: Ember.inject.service(),

    beforeModel() {
      if (!_environment.default.APP.FEATURES.DASHBOARD) {
        this.toast.error('No tiene permisos para acceder esta ruta.');

        return this.transitionTo('index');
      }
    }
  });
});