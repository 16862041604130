define('sirvo-web/routes/inventario', ['exports', 'sirvo-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    toast: Ember.inject.service(),

    beforeModel() {
      if (!_environment.default.APP.FEATURES.INVENTARIO) {
        this.toast.error('No tiene permisos para acceder esta ruta.');

        return this.transitionTo('index');
      }
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('currentTab', 'ARTICULOS');
    }
  });
});