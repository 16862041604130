define('sirvo-web/models/kar-bodegas', ['exports', '@ember-data/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // codempresa: attr('string'),

    codbodega: (0, _model.attr)('string'),

    descripcion: (0, _model.attr)('string'),

    noUsaControlInventario: (0, _model.attr)('number', { defaultValue: 0 }),

    metodoValuacion: (0, _model.attr)('number', { defaultValue: 1 }),

    esServicio: (0, _model.attr)('number', { defaultValue: 0 }),

    esMateriaPrima: (0, _model.attr)('number', { defaultValue: 0 }),

    esMaterialEmpaque: (0, _model.attr)('number', { defaultValue: 0 }),

    esProductoTerminado: (0, _model.attr)('number', { defaultValue: 0 }),

    esTransitoria: (0, _model.attr)('number', { defaultValue: 0 }),

    codbodegaEnProceso: (0, _model.attr)('string'),

    codbodegaEnTransito: (0, _model.attr)('string'),

    expresionVolumetrica: (0, _model.attr)('string'),

    diametro: (0, _model.attr)('number', { defaultValue: 0 }),

    altura: (0, _model.attr)('number', { defaultValue: 0 }),

    alturaCono: (0, _model.attr)('number', { defaultValue: 0 }),

    anguloCono: (0, _model.attr)('number', { defaultValue: 0 }),

    densidad: (0, _model.attr)('number', { defaultValue: 0 }),

    constante: (0, _model.attr)('number', { defaultValue: 0 }),

    constanteEnCaliente: (0, _model.attr)('number', { defaultValue: 0 }),

    constanteEnFrio: (0, _model.attr)('number', { defaultValue: 0 }),

    observaciones: (0, _model.attr)('string'),

    creusr: (0, _model.attr)('string'),

    fecusr: (0, _model.attr)('date'),

    creaFecha: (0, _model.attr)('date'),

    codusr: (0, _model.attr)('string'),

    // Relationships

    empresa: (0, _model.belongsTo)('empresas')
  });
});