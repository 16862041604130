define('sirvo-web/routes/admon/grupos/directorios/record', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    modelName: 'grudirectorio',

    model({ id }) {
      if ('new' === id) {
        return this.store.createRecord(this.get('modelName'));
      }

      return this.store.find(this.get('modelName'), id);
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('record', model);
    }
  });
});