define('sirvo-web/utils/error-handler', ['exports', 'ember-data', 'ember-changeset/utils/is-changeset'], function (exports, _emberData, _isChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.handleChangesetSaveErrors = handleChangesetSaveErrors;


  const { InvalidError } = _emberData.default;

  function handleChangesetSaveErrors(entity, toast, error) {
    // Ignore errors of type TransitionAborted.
    if (error.name && error.name === 'TransitionAborted') {
      return;
    }

    // TODO: Fix to get the errors correctly and add them to the changeset.
    if (error instanceof InvalidError && (0, _isChangeset.default)(entity)) {
      entity.get('_content').get('errors').forEach(error => {
        if ('base' === error.attribute) {
          return;
        }

        entity.addError(error.attribute, [error.message]);
      });
    }

    if (Array.isArray(error.errors)) {
      error.errors.forEach(err => {
        // Looks like a validation error?
        if (err.detail && err.source && err.source.pointer) {
          let attribute = err.source.pointer.split('/').lastObject;

          if ((0, _isChangeset.default)(entity)) {
            entity.addError(attribute, [err.detail]);
            entity.get('_content').rollbackAttributes();
          } else {
            toast.error(`${attribute}: ${err.detail}`);
          }
        } else {
          toast.error(err.title || err.message || 'Ocurrió un error.');
        }
      });
    } else {
      toast.error(error.message || 'Ocurrió un error.');
    }

    throw error;
  }

  exports.default = {
    handleChangesetSaveErrors
  };
});