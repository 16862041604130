define('sirvo-web/controllers/admon/grupos/contactos', ['exports', 'servir-ember-utilities/controllers/abstract-module-index', 'sirvo-web/models/grudirectoriocontactos'], function (exports, _abstractModuleIndex, _grudirectoriocontactos) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractModuleIndex.default.extend({
    modalOpen: false,

    modelName: 'proyecto',

    modelClass: _grudirectoriocontactos.default,

    init() {
      this._super(...arguments);
      this.set('selection', []);
      this.set('sorts', []);
      this.set('columns', [{
        label: 'Nombre',
        valuePath: 'nombre',
        headComponent: 'table-head',
        isShown: true,
        filtrable: true,
        filterName: 'nombre',
        isFixed: 'left'
      }, {
        label: 'Celular',
        valuePath: 'telefonomovil',
        headComponent: 'table-head',
        filtrable: true,
        filterName: 'telefonomovil',
        isShown: true
      }, {
        label: 'Correo',
        valuePath: 'email',
        headComponent: 'table-head',
        filtrable: true,
        filterName: 'email',
        isShown: true
      }, {
        label: 'Dirección',
        valuePath: 'direccion',
        headComponent: 'table-head',
        isShown: true
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    },

    actions: {
      submit() {}
    }
  });
});