define('sirvo-web/serializers/application', ['exports', '@ember-data/serializer/json-api', '@ember/string'], function (exports, _jsonApi, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    modelNameFromPayloadKey(key) {
      return (0, _string.dasherize)(key);
    }
  });
});