define('sirvo-web/controllers/dashboard/contratos', ['exports', 'moment', 'sirvo-web/utils/format', 'sirvo-web/utils/date'], function (exports, _moment, _format, _date) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    request: Ember.inject.service(),

    loader: Ember.inject.service(),

    selectedEmpresa: null,

    contratos: null,

    selectedContrato: null,

    facturas: null,

    totalFacturado: 0,

    totalCobrado: 0,

    totalPendiente: Ember.computed('totalFacturado', 'totalCobrado', function () {
      return (0, _format.round)(this.totalFacturado - this.totalCobrado);
    }),

    contratoSelectedData: Ember.computed('selectedContrato', function () {
      if (!this.selectedContrato) {
        return [];
      }

      return [this.selectedContrato];
    }),

    generalesColumns: Ember.computed(function () {
      return [{
        label: 'Local',
        valuePath: 'lote',
        width: '200px',
        sortable: true
      }, {
        label: 'Mts 2',
        valuePath: 'cantidad_m2',
        width: '110px',
        sortable: false,
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Costo por Mt 2',
        valuePath: 'valor_m2',
        width: '140px',
        sortable: false,
        emberIntlCurrency: 'GTQ',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Inquilino',
        valuePath: 'inquilino',
        width: '200px',
        sortable: true
      }];
    }),

    plazoColumns: Ember.computed(function () {
      return [{
        label: 'Fecha inicio',
        valuePath: 'fecha_ini',
        width: '140px',
        sortable: true
      }, {
        label: 'Fecha fin',
        valuePath: 'fecha_fin',
        width: '130px',
        sortable: true
      }];
    }),

    vigenciaColumns: Ember.computed(function () {
      return [{
        label: 'Vence (1 mes)',
        valuePath: 'contractExpOneMonth',
        width: '160px',
        sortable: false,
        cellComponent: 'tables/cell/color-mark',
        markColor: 'red'
      }, {
        label: 'Vence (2 meses)',
        valuePath: 'contractExpTwoMonths',
        width: '160px',
        sortable: false,
        cellComponent: 'tables/cell/color-mark',
        markColor: 'orange'
      }, {
        label: 'Vence (3 meses)',
        valuePath: 'contractExpThreeMonths',
        width: '160px',
        sortable: false,
        cellComponent: 'tables/cell/color-mark',
        markColor: 'green'
      }];
    }),

    contratosColumns: Ember.computed(function () {
      return [...this.generalesColumns, ...this.plazoColumns, ...this.vigenciaColumns];
    }),

    contratoSelectedColumns: Ember.computed(function () {
      return [...this.generalesColumns, ...this.plazoColumns];
    }),

    facturasColumns: Ember.computed(function () {
      return [{
        label: 'Mes',
        valuePath: 'fechaFormatted',
        width: '130px',
        sortable: false
      }, {
        label: '# de factura',
        valuePath: 'factura',
        width: '150px',
        sortable: false
      }, {
        label: 'Facturado',
        valuePath: 'facturado',
        width: '130px',
        sortable: false,
        totalValuePath: 'facturado',
        emberIntlCurrency: 'GTQ',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Cobrado',
        valuePath: 'cobrado',
        width: '130px',
        sortable: false,
        totalValuePath: 'cobrado',
        emberIntlCurrency: 'GTQ',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Pendiente',
        valuePath: 'pendiente',
        width: '130px',
        sortable: false,
        totalValuePath: 'pendiente',
        emberIntlCurrency: 'GTQ',
        cellComponent: 'tables/cell/accounting-amount'
      }];
    }),

    chartData: Ember.computed('totalCobrado', 'totalPendiente', function () {
      return {
        datasets: [{
          data: [this.totalCobrado, this.totalPendiente],
          backgroundColor: ['#FFC000', '#0070C0']
          // hoverBackgroundColor: 'gray',
        }],
        labels: ['Cobrado', 'Pendiente']
      };
    }),

    chartOptions: Ember.computed(function () {
      return {
        title: {
          display: true,
          text: 'Pendiente Cobro',
          fontSize: 17,
          fontColor: 'black',
          padding: 14
        },
        legend: {
          position: 'bottom'
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, chart) => {
              let data = chart.datasets[0].data;
              let total = data.reduce((total, val) => total + val, 0);
              let percentage = (0, _format.round)(data[tooltipItem.index] / total * 100);

              return `${percentage} %`;
            }
          }
        }
      };
    }),

    empresaUpdated() {
      this.setProperties({
        contratos: [],
        selectedContrato: null,
        facturas: [],
        totalFacturado: 0,
        totalCobrado: 0
      });

      if (!this.selectedEmpresa || !this.selectedEmpresa.get('id')) {
        return;
      }

      let empresaId = this.selectedEmpresa.get('id');

      this.loader.isLoading();
      this.request.get('dashboard/contratos/informativa', { empresaId }).then(contratos => {
        let transformedData = contratos.map(contrato => {
          let vigenciaMes = parseInt(contrato.vigencia_meses);
          let vigenciaMesValido = !isNaN(vigenciaMes);

          Ember.setProperties(contrato, {
            cantidad_m2: parseFloat(contrato.cantidad_m2) || contrato.cantidad_m2,
            valor_m2: parseFloat(contrato.valor_m2) || contrato.valor_m2,
            contractExpOneMonth: vigenciaMesValido && vigenciaMes >= 0 && vigenciaMes <= 1,
            contractExpTwoMonths: vigenciaMesValido && vigenciaMes === 2,
            contractExpThreeMonths: vigenciaMesValido && vigenciaMes > 2
          });

          return contrato;
        });

        this.set('contratos', transformedData);
      }).finally(() => this.loader.notLoading());
    },

    actions: {
      empresaUpdated() {
        this.empresaUpdated();
      },

      onSelectContrato(contrato) {
        this.set('selectedContrato', contrato);

        let contratoId = contrato.get('contrato_id');

        if (!this.selectedEmpresa || !contratoId) {
          this.set('facturas', []);

          return;
        }

        let empresaId = this.selectedEmpresa.get('id');

        this.loader.isLoading();
        this.request.get('dashboard/contratos/financiera', { empresaId, contratoId }).then(facturas => {
          let totalFacturado = 0;
          let totalCobrado = 0;
          let transformedData = facturas.map(factura => {
            let fecha = factura.fecha ? (0, _moment.default)(factura.fecha) : null;
            let cobrado = parseFloat(factura.cobrado);
            let facturado = parseFloat(factura.facturado);

            Ember.setProperties(factura, {
              fechaFormatted: fecha && fecha.isValid() ? `${(0, _date.monthName)(fecha.month())} - ${fecha.year()}` : null,
              pendiente: !isNaN(cobrado) && !isNaN(facturado) ? (0, _format.round)(facturado - cobrado) : null
            });

            totalFacturado += facturado || 0;
            totalCobrado += cobrado || 0;

            return factura;
          });

          this.setProperties({
            facturas: transformedData,
            totalFacturado,
            totalCobrado
          });
        }).finally(() => this.loader.notLoading());
      }
    }
  });
});