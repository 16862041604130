define('sirvo-web/router', ['exports', 'sirvo-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('404', { path: '**' });

    this.route('login');

    this.route('logout');

    this.route('inventario', function () {
      this.route('articulos', function () {
        this.route('record', { path: '/:id' }, function () {
          this.route('datos-generales');
          this.route('registro');
          this.route('configuracion');
          this.route('unidades-medida');
        });
      });

      this.route('bodegas', function () {
        this.route('record', { path: '/:id' }, function () {
          this.route('datos-generales');
          this.route('configuraciones');
          this.route('dimensiones');
        });
      });
    });

    this.route('dashboard', function () {
      this.route('contratos');
      this.route('arrendamiento');
      this.route('parqueos');
      this.route('resultados');
    });

    this.route('admon', function () {
      this.route('grupos', function () {
        this.route('contactos');

        this.route('directorios', function () {
          this.route('record', { path: '/:id' });
        });
      });

      this.route('directorio', function () {
        this.route('contactos');
      });
    });
  });

  exports.default = Router;
});