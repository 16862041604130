define('sirvo-web/helpers/form-for/humanize', ['exports', 'ember-form-for/helpers/form-for/humanize'], function (exports, _humanize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _humanize.default;
    }
  });
  Object.defineProperty(exports, 'formForHumanize', {
    enumerable: true,
    get: function () {
      return _humanize.formForHumanize;
    }
  });
});