define('sirvo-web/mirage-fixtures/cities', ['exports', 'servir-ember-utilities/mirage-fixtures/cities'], function (exports, _cities) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cities.default;
    }
  });
});