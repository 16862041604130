define('sirvo-web/models/vfusers', ['exports', '@ember-data/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    idvfusers: (0, _model.attr)('string'),
    codempresa: (0, _model.attr)('string'),
    coduser: (0, _model.attr)('string'),
    perfil: (0, _model.attr)('string'),
    nombre: (0, _model.attr)('string'),
    puesto: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    emailpassword: (0, _model.attr)('string'),
    emailtipo: (0, _model.attr)('string'),
    emailserver: (0, _model.attr)('string'),
    emailport: (0, _model.attr)('string'),
    emailautentica: (0, _model.attr)('string'),
    emailssl: (0, _model.attr)('string'),
    supervisor: (0, _model.attr)('string'),
    feciniexp: (0, _model.attr)('date'),
    diasexp: (0, _model.attr)('string'),
    fecexp: (0, _model.attr)('date'),
    codpassword: (0, _model.attr)('string'),
    codpass: (0, _model.attr)('string'),
    recodificarPasswordASha2: (0, _model.attr)('string'),
    esInactivo: (0, _model.attr)('string'),
    fechaBaja: (0, _model.attr)('date'),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string'),
    stausr: (0, _model.attr)('string'),
    creaFecha: (0, _model.attr)('date'),
    creaUsuario: (0, _model.attr)('string'),
    creaEstacion: (0, _model.attr)('string')
  });
});