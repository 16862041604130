define('sirvo-web/components/confirmation-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init() {
      this._super(...arguments);
      (true && !(Ember.typeOf(this.onConfirm) === 'function') && Ember.assert('@onConfirm must be a action', Ember.typeOf(this.onConfirm) === 'function'));
    }
  });
});