define('sirvo-web/controllers/admon/directorio/contactos', ['exports', 'servir-ember-utilities/controllers/abstract-module-index', 'sirvo-web/models/directorio-contactos'], function (exports, _abstractModuleIndex, _directorioContactos) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractModuleIndex.default.extend({
    modalOpen: false,

    loader: Ember.inject.service(),

    modelName: 'proyecto',

    modelClass: _directorioContactos.default,

    selectedDirectorioContacto: null,

    directorio: null,

    contacto: null,

    init() {
      this._super(...arguments);
      this.set('selection', []);
      this.set('sorts', []);
      this.set('columns', [{
        label: 'Código directorio',
        valuePath: 'coddirectorio',
        filtrable: true,
        filterName: 'coddirectorio',
        isShown: true
      }, {
        label: 'Nombre directorio',
        valuePath: 'nombredirectorio',
        isShown: true
      }, {
        label: 'Razón social',
        valuePath: 'razonsocial',
        isShown: true
      }, {
        label: 'Nombre contacto',
        valuePath: 'nombre',
        isShown: true
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        filtrable: true,
        filterName: 'nombre',
        hidden: true
      }]);
    },

    actions: {
      onSelectedDirectorioContacto(directorioContacto) {

        this.loader.isLoading();

        let promises = {
          directorios: this.store.query('grudirectorio', { filter: {
              iddirectorio: directorioContacto.get('iddirectorio')
            } }),
          contactos: directorioContacto.get('idcontacto') ? this.store.query('grudirectoriocontactos', { filter: {
              id: directorioContacto.get('idcontacto')
            } }) : Ember.A()
        };

        return Ember.RSVP.hash(promises).then(promises => {
          this.setProperties({
            directorio: promises.directorios.firstObject,
            contacto: promises.contactos.firstObject,
            selectedDirectorioContacto: directorioContacto
          });
        }).catch(error => {
          this.toast.error('No se pudo completar el proceso.');

          throw error;
        }).finally(() => this.loader.notLoading());
      },

      close() {
        this.set('selectedDirectorioContacto', null);
      }
    }
  });
});