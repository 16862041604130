define('sirvo-web/controllers/popups/select-year', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    title: null,
    message: null,
    currentYear: null,

    anios: Ember.computed(function () {
      let nowYear = new Date().getFullYear();
      let anios = [nowYear];
      let cont = 0;

      while (cont++ < 11) {
        anios.push(nowYear - cont);
      }

      return anios;
    }),

    init() {
      this._super(...arguments);

      this.doOnSubmit = this.doOnSubmit.bind(this);
    },

    onSubmit() {},

    doOnSubmit() {
      this.onCancel();
      this.onSubmit(this.currentYear);
    },

    onCancel() {}
  });
});