define('sirvo-web/helpers/fn', ['exports', 'ember-fn-helper-polyfill/helpers/fn'], function (exports, _fn) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fn.default;
    }
  });
  Object.defineProperty(exports, 'fn', {
    enumerable: true,
    get: function () {
      return _fn.fn;
    }
  });
});