define('sirvo-web/controllers/inventario/articulos/record/unidades-medida', ['exports', 'servir-ember-utilities/controllers/abstract-module-index', 'ember-changeset', 'ember-changeset-validations', 'sirvo-web/validations/tabla-medida', 'sirvo-web/utils/error-handler'], function (exports, _abstractModuleIndex, _emberChangeset, _emberChangesetValidations, _tablaMedida, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractModuleIndex.default.extend({
    toast: Ember.inject.service(),

    company: Ember.computed.readOnly('currentCompany.company'),

    unidadMedidaChangeset: null,

    unidadesMedidaColumns: Ember.computed(function () {
      return [{
        label: 'Código',
        valuePath: 'codmedida',
        width: '100px',
        sortable: false
      }, {
        label: 'Equivalencia',
        valuePath: 'cantidad',
        width: '100px',
        sortable: false
      }, {
        label: 'Compra',
        valuePath: 'compra',
        width: '80px',
        align: 'center',
        sortable: false,
        format: value => Boolean(value),
        cellComponent: 'tables/cell/checkbox-field'
      }, {
        label: 'Venta',
        valuePath: 'venta',
        width: '80px',
        align: 'center',
        sortable: false,
        format: value => Boolean(value),
        cellComponent: 'tables/cell/checkbox-field'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          preDelete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.preDelete.bind(this)
          }
        }
      }];
    }),

    destroyCurrentRecord() {
      if (!this.unidadMedidaChangeset) {
        return;
      }

      this.unidadMedidaChangeset.rollback();

      let unidadMedida = this.unidadMedidaChangeset.get('_content');

      if (unidadMedida.get('isNew')) {
        unidadMedida.destroyRecord();
      }
    },

    createNewChangeset(unidadMedida = null) {
      if (unidadMedida && !unidadMedida.isValidated) {
        this.destroyCurrentRecord();
      }

      if (!unidadMedida) {
        unidadMedida = this.store.createRecord('kar-tabla-medida', {
          empresa: this.company,
          articulo: this.record,
          isValidated: false
        });
      }

      this.set('unidadMedidaChangeset', new _emberChangeset.default(unidadMedida, (0, _emberChangesetValidations.default)(_tablaMedida.default), _tablaMedida.default));
    },

    actions: {
      saveUnidadMedida() {
        return this.unidadMedidaChangeset.validate().then(() => {
          if (!this.unidadMedidaChangeset.get('isValid')) {
            this.toast.error('El formulario tiene errores.');

            return;
          }

          this.unidadMedidaChangeset.set('isValidated', true);

          if (this.changeset.get('id')) {
            return this.unidadMedidaChangeset.save().then(() => {
              this.toast.success('Unidad de medida guardada correctamente.');
              this.createNewChangeset();
            }).catch(_errorHandler.handleChangesetSaveErrors.bind(null, this.unidadMedidaChangeset, this.toast));
          }

          this.unidadMedidaChangeset.execute();
          this.createNewChangeset();
        });
      },

      edit(unidadMedida) {
        if (unidadMedida === this.unidadMedidaChangeset.get('_content')) {
          return;
        }

        this.createNewChangeset(unidadMedida);
      },

      preDelete(unidadMedida) {
        if (unidadMedida === this.unidadMedidaChangeset.get('_content')) {
          return;
        }

        this.actions.delete.call(this, ...arguments);
      }
    }
  });
});