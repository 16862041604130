define('sirvo-web/transforms/zero-date', ['exports', '@ember-data/serializer/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Esto es un workaround a campos en MySQL con not nullable y default 0000-00-00 00:00:00 o 0000-00-00 (no son fechas válidas)
   *
   * El código de `deserialize` y `serialize` se copio de DateTransform de ember-data, es una clase privada.
   */
  class ZeroDateTransform extends _transform.default {

    deserialize(serialized) {
      // Zero date workaround
      // -0001-11-30T00:00:00-06:00 is 0000-00-00 00:00 for ApiPlatform :S
      if (serialized === '-0001-11-30T00:00:00-06:00') {
        serialized = null;
      }
      // End zero date workaround

      let type = typeof serialized;

      if (type === 'string') {
        let offset = serialized.indexOf('+');

        if (offset !== -1 && serialized.length - 5 === offset) {
          offset += 3;
          return new Date(serialized.slice(0, offset) + ':' + serialized.slice(offset));
        }
        return new Date(serialized);
      } else if (type === 'number') {
        return new Date(serialized);
      } else if (serialized === null || serialized === undefined) {
        // if the value is null return null
        // if the value is not present in the data return undefined
        return serialized;
      } else {
        return null;
      }
    }

    serialize(date) {
      if (date instanceof Date && !isNaN(date)) {
        return date.toISOString();
      } else {
        // Deberia ser 0000-00-00 00:00:00 pero ApiPlatform generar una fecha inválida con ese error
        return new Date().toISOString();
      }
    }
  }
  exports.default = ZeroDateTransform;
});