define('sirvo-web/controllers/admon/grupos/directorios/record', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    indexRoute: 'inventario.directorios',

    actions: {
      delete() {
        this.record.destroyRecord().then(() => {
          this.transitionToRoute(this.get('indexRoute'));
        }).catch(error => {
          Ember.debug(error);
        });
      },

      submit() /*model*/{
        return this.model.save();
      },

      onClose() {
        this.record.rollbackAttributes();

        this.transitionToRoute(this.get('indexRoute'));
      }
    }
  });
});