define('sirvo-web/services/toast', ['exports', 'sirvo-web/config/environment', 'ember-toastr/services/toast'], function (exports, _environment, _toast) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const toastrOptions = {
    closeButton: true,
    debug: false,
    newestOnTop: true,
    progressBar: true,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '4000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
  };
  const config = _environment.default['ember-toastr'] || {
    toastrOptions: toastrOptions
  };

  exports.default = _toast.default.extend({
    defaultToastrOptions: toastrOptions,
    config: config
  });
});