define('sirvo-web/models/kar-tipoarticulos', ['exports', '@ember-data/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    codempresa: (0, _model.attr)('string'),

    codbodega: (0, _model.attr)('string'),

    codtipoart: (0, _model.attr)('string'),

    codtipoartPadre: (0, _model.attr)('string'),

    descripcion: (0, _model.attr)('string'),

    cuentaCosto: (0, _model.attr)('string'),

    codca: (0, _model.attr)('string'),

    codcc: (0, _model.attr)('string'),

    codci: (0, _model.attr)('string'),

    creusr: (0, _model.attr)('string'),

    fecusr: (0, _model.attr)('date'),

    codusr: (0, _model.attr)('string'),

    stausr: (0, _model.attr)('string'),

    creaFecha: (0, _model.attr)('date'),

    creaUsuario: (0, _model.attr)('string'),

    creaEstacion: (0, _model.attr)('string'),

    // Relationships

    empresa: (0, _model.belongsTo)('empresas')
  });
});