define('sirvo-web/components/tables/cell/color-mark', ['exports', 'sirvo-web/templates/components/tables/cell/color-mark'], function (exports, _colorMark) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _colorMark.default,
    row: null,
    table: null,
    color: null,

    init() {
      this._super(...arguments);

      this.set('color', this.get('column.markColor') || 'gray');
    }
  });
});