define('sirvo-web/controllers/admon/grupos/directorios', ['exports', 'servir-ember-utilities/controllers/abstract-module-index', 'sirvo-web/models/grudirectorio'], function (exports, _abstractModuleIndex, _grudirectorio) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractModuleIndex.default.extend({
    modalOpen: false,

    request: Ember.inject.service(),

    loader: Ember.inject.service(),

    modelName: 'proyecto',

    contactos: null,

    modelClass: _grudirectorio.default,

    selectedDirectorio: null,

    contactosDirectorio: null,

    editRoute: 'inventario.directorios.record',

    modelClassContacto: Ember.computed('contactosDirectorio', function () {
      if (!this.contactosDirectorio) {
        return [];
      }

      return [this.contactosDirectorio];
    }),

    init() {
      this._super(...arguments);
      this.set('selection', []);
      this.set('sorts', []);
      this.set('columns', [{
        label: 'Código',
        valuePath: 'coddirectorio',
        headComponent: 'table-head',
        filtrable: true,
        filterName: 'coddirectorio',
        isShown: true,
        sortable: false,
        isFixed: 'left'
      }, {
        label: 'Grupo',
        valuePath: 'codgrupo',
        headComponent: 'table-head',
        sortable: false,
        isShown: true
      }, {
        label: 'Razón Social',
        valuePath: 'razonsocial',
        headComponent: 'table-head',
        filtrable: true,
        filterName: 'razonsocial',
        sortable: false,
        isShown: true
      }, {
        label: 'Sucursal',
        valuePath: 'sucursal_mb',
        headComponent: 'table-head',
        sortable: false,
        isShown: true
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    },

    contactosColumns: Ember.computed(function () {
      return [{
        label: 'Nombre',
        valuePath: 'nombre',
        sortable: false
      }, {
        label: 'Celular',
        valuePath: 'telefonomovil',
        sortable: false
      }, {
        label: 'Correo',
        valuePath: 'email',
        sortable: false
      }];
    }),

    actions: {
      submit() {},

      onSelectDirectorio(directorio) {
        this.set('selectedDirectorio', directorio);

        let coddirectorio = directorio.get('coddirectorio');

        this.loader.isLoading();

        return this.store.query('grudirectoriocontactos', { filter: { coddirectorio: coddirectorio } }).then(contactos => {
          let transformedData = contactos.map(contacto => {

            Ember.setProperties(contacto, {
              name: contacto.get('nombre'),
              celular: contacto.get('telefonomovil'),
              correo: contacto.get('email')
            });

            return contacto;
          });

          this.setProperties({
            contactosDirectorio: transformedData
          });
        }).catch(error => {
          this.toast.error('No se pudo completar el proceso.');

          throw error;
        }).finally(() => this.loader.notLoading());
      }

    }
  });
});