define('sirvo-web/models/empresas', ['exports', '@ember-data/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    idempresa: (0, _model.attr)('number'),

    codempresa: (0, _model.attr)('string'),

    codgrupo: (0, _model.attr)('string'),

    codpais: (0, _model.attr)('string'),

    coddepto: (0, _model.attr)('string'),

    codmuni: (0, _model.attr)('string'),

    codzona: (0, _model.attr)('string'),

    codmoneda: (0, _model.attr)('string'),

    codmonedaMe: (0, _model.attr)('string'),

    nombreemp: (0, _model.attr)('string'),

    razonsocial: (0, _model.attr)('string'),

    codigoNombre: Ember.computed('codempresa', 'nombreemp', function () {
      return [this.codempresa, this.nombreemp].filter(Boolean).join(' - ');
    })
  });
});