define('sirvo-web/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _applicationRouteMixin, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    intl: Ember.inject.service(),

    session: Ember.inject.service(),

    currentUser: Ember.inject.service(),

    /**
     * @private
     */
    renderModal(template) {
      this.render(template, {
        outlet: 'modal',
        into: 'application'
      });
    },

    /**
     * @private
     */
    closeModal() {
      this.disconnectOutlet({
        outlet: 'modal',
        parentView: 'application'
      });
    },

    beforeModel() {
      this._super(...arguments);
      this.intl.setLocale('es-GT');

      if (this.session.isAuthenticated) {
        return this._loadCurrentUser();
      }
    },

    /**
     * @private
     */
    _loadCurrentUser() {
      return this.get('currentUser').load().catch(e => {
        this.session.invalidate();

        throw e;
      });
    },

    /**
     * @inherit
     *
     * After session gets authenticated, load the current user.
     */
    sessionAuthenticated() {
      const callSuper = this._super.bind(this);

      this._loadCurrentUser().then(callSuper);

      this.transitionTo('index');
    },

    actions: {
      closeModal() {
        this.closeModal();
      },

      openModal(modalTemplatePath, controllerProperties) {
        if (!Ember.isNone(controllerProperties)) {
          Ember.setProperties(this.controllerFor(modalTemplatePath), controllerProperties);
        }

        this.renderModal(modalTemplatePath);
      }
    }
  });
});