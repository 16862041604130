define('sirvo-web/controllers/application', ['exports', 'sirvo-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isNavbarCollapsed: false,

    session: Ember.inject.service(),

    loader: Ember.inject.service(),

    currentUser: Ember.inject.service(),

    user: Ember.computed.readOnly('currentUser.user'),

    isLoading: Ember.computed.readOnly('loader.loading'),

    showDashboard: _environment.default.APP.FEATURES.DASHBOARD,

    showInventario: _environment.default.APP.FEATURES.INVENTARIO,

    isAuthenticated: Ember.computed('session.isAuthenticated', function () {
      return this.session.get('isAuthenticated');
    }),

    actions: {
      toggleMenu(menu) {
        Ember.set(this.menu, menu, !Ember.get(this.menu, menu));
      },

      toggleNavbar() {
        this.set('isNavbarCollapsed', !this.isNavbarCollapsed);
      }
    }
  });
});