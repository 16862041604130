define('sirvo-web/routes/inventario/bodegas/record', ['exports', 'servir-ember-utilities/routes/abstract-module-record', 'servir-ember-utilities/mixins/route/has-tabs'], function (exports, _abstractModuleRecord, _hasTabs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    modelName: 'kar_bodegas',

    indexRoute: 'inventario.bodegas',

    defaultTabSubRoute: 'datos-generales',

    createRecordInstance() {
      return this.store.createRecord(this.get('modelName'), {
        empresa: this.company,
        codempresa: this.company.get('codempresa')
      });
    }
  });
});