define('sirvo-web/instance-initializers/form-for-initializer', ['exports', 'sirvo-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  const { merge, set } = Ember;

  const DEFAULT_CONFIG = {
    buttonClasses: ['form-button'],
    fieldClasses: ['form-field'],
    fieldHasErrorClasses: ['form-field--has-errors'],
    errorClasses: ['form-field--errors'],
    hintClasses: ['form-field--hint'],
    inputClasses: ['form-field--control'],
    labelClasses: ['form-field--label'],
    resetClasses: ['form-button--reset'],
    submitClasses: ['form-button--submit']
  };

  function initialize(application) {
    let formForConfig = merge(DEFAULT_CONFIG, _environment.default['ember-form-for']);
    let configService = application.lookup('service:ember-form-for/config');

    Object.keys(formForConfig).forEach(key => {
      set(configService, key, formForConfig[key]);
    });
  }

  exports.default = {
    name: 'form-for-initializer',
    initialize
  };
});