define('sirvo-web/components/form-fields/month-field', ['exports', 'ember-form-for/components/form-fields/month-field'], function (exports, _monthField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _monthField.default;
    }
  });
});