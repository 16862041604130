define('sirvo-web/routes/dashboard/contratos', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    loader: Ember.inject.service(),

    empresaActual: (0, _emberLocalStorage.storageFor)('empresa'),

    model() {
      this.loader.isLoading();

      return Ember.RSVP.hash({
        empresas: this.currentUser.fetchUserEmpresas()
      }).finally(() => this.loader.notLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        selectedEmpresa: null,
        selectedContrato: null,
        contratos: [],
        facturas: []
      });

      let empresaActualId = this.empresaActual.get('content.id');
      if (empresaActualId) {
        let empresaObject = model.empresas.findBy('id', String(empresaActualId));

        if (empresaObject) {
          controller.set('selectedEmpresa', empresaObject);
          controller.empresaUpdated();
        }
      }
    }
  });
});