define('sirvo-web/services/current-company', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),

    store: Ember.inject.service(),

    toast: Ember.inject.service(),

    loader: Ember.inject.service(),

    company: null,

    load() {
      const token = this.session.data.authenticated.token;

      if (!token) {
        throw Error('User not authenticated.');
      }

      const authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
      const userData = authenticator.getTokenData(token).username.split('@', 2);

      if (!userData || userData.length < 2) {
        throw Error('Empresa code not found from session data.');
      }

      const codempresa = userData[1];

      this.loader.isLoading();

      return this.store.query('empresas', { filter: { codempresa: { exact: codempresa } } }).then(result => {

        if (0 === result.get('length')) {
          throw Error(`Got no record for 'empresas' with code '${codempresa}'.`);
        }

        if (1 !== result.get('length')) {
          throw Error(`Got more than one record for 'empresas' with code '${codempresa}'.`);
        }

        this.set('company', result.get('firstObject'));
      }).then(() => this.company).catch(error => {
        this.toast.error('No es posible acceder en este momento.');

        throw error;
      }).finally(() => this.loader.notLoading());
    }
  });
});