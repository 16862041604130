define('sirvo-web/adapters/application', ['exports', '@ember-data/adapter/json-api', 'ember-simple-auth/mixins/data-adapter-mixin', 'sirvo-web/config/environment'], function (exports, _jsonApi, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    host: _environment.default.API.host,
    namespace: _environment.default.API.namespace,

    headers: Ember.computed('session.data.authenticated.token', function () {
      // TODO: Fix this at the 'ember-data' level.
      let headers = {
        'Content-Type': 'application/vnd.api+json'
      };

      if (this.session.isAuthenticated) {
        // OAuth 2
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.token}`;
      }

      return headers;
    })
  });
});