define('sirvo-web/controllers/inventario', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentTab: null,

    editRoute: Ember.computed('currentTab', function () {
      switch (this.currentTab) {
        case 'ARTICULOS':
          return 'inventario.articulos.record';
        case 'DIRECTORIOS':
          return 'inventario.directorios.record';
        case 'BODEGAS':
          return 'inventario.bodegas.record';
        default:
          throw Error(`No edit route for tab '${this.currentTab}'.`);
      }
    }),

    actions: {
      addRecord() {
        this.transitionToRoute(this.get('editRoute'), 'new');
      }
    }
  });
});