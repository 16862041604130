define('sirvo-web/controllers/inventario/articulos', ['exports', 'servir-ember-utilities/controllers/abstract-module-index', 'sirvo-web/models/kar-articulos'], function (exports, _abstractModuleIndex, _karArticulos) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractModuleIndex.default.extend({
    modalOpen: false,

    modelName: 'proyecto',

    modelClass: _karArticulos.default,

    editRoute: 'inventario.articulos.record',

    init() {
      this._super(...arguments);
      this.set('selection', []);
      this.set('sorts', []);
      this.set('columns', [{
        label: 'Código',
        valuePath: 'correlativo',
        headComponent: 'table-head',
        isShown: true,
        filtrable: true,
        filterName: 'correlativo',
        isFixed: 'left'
      }, {
        label: 'Código empresa',
        valuePath: 'empresa.nombreemp',
        headComponent: 'table-head',
        filtrable: true,
        filterName: 'empresa.nombreemp',
        isShown: true
      }, {
        label: 'Código medida',
        valuePath: 'codmedidaBase',
        headComponent: 'table-head',
        filtrable: true,
        filterName: 'codmedidaBase',
        isShown: true
      }, {
        label: 'Descripción',
        valuePath: 'descripcion',
        headComponent: 'table-head',
        isShown: true
      }, {
        label: 'Existencia max.',
        valuePath: 'existenciaMax',
        headComponent: 'table-head',
        isShown: true
      }, {
        label: 'Existencia min.',
        valuePath: 'existenciaMin',
        headComponent: 'table-head',
        isShown: true
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);

      // this.set('activeModelFilters', [
      //   {
      //     selectedFilter: { filterName: 'codarticulo' },
      //     value: ['sdfsdf']
      //   }
      // ]);
    },

    actions: {
      submit() {}
    }
  });
});