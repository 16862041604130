define('sirvo-web/helpers/loc', ['exports', '@ember/string/helpers/loc'], function (exports, _loc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loc.default;
    }
  });
  Object.defineProperty(exports, 'loc', {
    enumerable: true,
    get: function () {
      return _loc.loc;
    }
  });
});