define('sirvo-web/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),

    store: Ember.inject.service(),

    currentCompany: Ember.inject.service(),

    toast: Ember.inject.service(),

    loader: Ember.inject.service(),

    user: null,

    load() {
      const token = this.session.data.authenticated.token;

      if (!token) {
        throw Error('User not authenticated.');
      }

      const authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
      const [username, codempresa] = authenticator.getTokenData(token).username.split('@', 2);

      if (!username) {
        throw Error('Username not found from session data.');
      }

      this.loader.isLoading();

      return this.store.query('vfusers', { filter: { coduser: { exact: username }, codempresa: { exact: codempresa } } }).then(result => {
        if (0 === result.get('length')) {
          throw new Error(`Got no record for 'usuario' with username '${username}'`);
        }

        if (1 !== result.get('length')) {
          throw new Error(`Got more than one record for 'usuario' with username '${username}'`);
        }

        this.set('user', result.get('firstObject'));
        // Pendiente desarrrollar sistema de permisos con Sirvo
        // return this.loadPermissions();
      }).then(() => this.currentCompany.load()).then(() => this.user).catch(error => {
        this.toast.error('No es posible acceder en este momento.');

        throw error;
      }).finally(() => this.loader.notLoading());
    },

    fetchUserEmpresas() {
      let emptyResolver = new Promise(resolve => resolve([]));

      if (!this.user) {
        return emptyResolver;
      }

      return this.store.query('vfusers', { filter: { coduser: this.user.get('coduser') } }).then(users => {
        let empresasCodes = users.mapBy('codempresa');

        if (!empresasCodes.length) {
          return emptyResolver;
        }

        return this.store.query('empresas', { filter: { codempresa: empresasCodes } });
      });
    }
  });
});