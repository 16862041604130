define('sirvo-web/models/kar-tabla-medida', ['exports', '@ember-data/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    codmedida: (0, _model.attr)('string'),

    cantidad: (0, _model.attr)('number', { defaultValue: 1 }),

    compra: (0, _model.attr)('number', { defaultValue: 1 }),

    venta: (0, _model.attr)('number', { defaultValue: 1 }),

    creusr: (0, _model.attr)('string'),

    fecusr: (0, _model.attr)('date'),

    codusr: (0, _model.attr)('string'),

    // Relationships

    empresa: (0, _model.belongsTo)('empresas'),

    articulo: (0, _model.belongsTo)('kar-articulos', { inverse: 'unidadesMedida' })
  });
});