define('sirvo-web/models/grudirectorioclasifica', ['exports', '@ember-data/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    iddirclasifica: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    codgrupo: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    codclasifica: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    coddirectorio: (0, _model.attr)('string'),
    cuentaRef: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    fechaBaja: (0, _model.attr)('date', {
      defaultValue: () => new Date()
    }),
    creusr: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    fecusr: (0, _model.attr)('date', {
      defaultValue: () => new Date()
    }),
    codusr: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    stausr: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    creaFecha: (0, _model.attr)('date', {
      defaultValue: () => new Date()
    }),
    creaUsuario: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    creaEstacion: (0, _model.attr)('string', {
      defaultValue: ''
    })
  });
});