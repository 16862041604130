define('sirvo-web/helpers/form-for/merge-custom-form-field', ['exports', 'ember-form-for/helpers/form-for/merge-custom-form-field'], function (exports, _mergeCustomFormField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mergeCustomFormField.default;
    }
  });
  Object.defineProperty(exports, 'formForMergeCustomFormField', {
    enumerable: true,
    get: function () {
      return _mergeCustomFormField.formForMergeCustomFormField;
    }
  });
});