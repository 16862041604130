define('sirvo-web/models/directorio-contactos', ['exports', '@ember-data/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    iddirectorio: (0, _model.attr)('number'),

    coddirectorio: (0, _model.attr)('string'),

    nombredirectorio: (0, _model.attr)('string'),

    razonsocial: (0, _model.attr)('string'),

    idcontacto: (0, _model.attr)('string'),

    nombre: (0, _model.attr)('string')
  });
});