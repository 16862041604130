define('sirvo-web/helpers/keys', ['exports', 'ember-composable-helpers/helpers/keys'], function (exports, _keys) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _keys.default;
    }
  });
  Object.defineProperty(exports, 'keys', {
    enumerable: true,
    get: function () {
      return _keys.keys;
    }
  });
});