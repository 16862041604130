define('sirvo-web/validations/directorio', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    razonSocial: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 4 })],
    nombreComercial: (0, _validators.validatePresence)(true)
  };
});