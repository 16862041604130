define('sirvo-web/routes/inventario/articulos/record/unidades-medida', ['exports', 'servir-ember-utilities/mixins/route/is-tab-subroute'], function (exports, _isTabSubroute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    setupController(controller, model) {
      this._super(controller, model);

      controller.createNewChangeset();
    },

    actions: {
      willTransition() {
        this.controller.destroyCurrentRecord();

        return true;
      }
    }
  });
});