define('sirvo-web/validations/tabla-medida', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    articulo: (0, _validators.validatePresence)({ presence: true }),
    empresa: (0, _validators.validatePresence)({ presence: true }),
    codmedida: (0, _validators.validatePresence)({ presence: true }),
    cantidad: (0, _validators.validatePresence)({ presence: true })
  };
});