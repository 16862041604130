define('sirvo-web/utils/date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const monthNamesEs = exports.monthNamesEs = {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre'
  };

  const monthName = exports.monthName = monthIndex => {
    if (monthIndex < 0 || monthIndex > 11) {
      return '';
    }

    return monthNamesEs[monthIndex];
  };
});