define('sirvo-web/models/custom-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { inflector } = _emberInflector.default;

  inflector.rules.irregular = [];
  inflector.rules.irregularInverse = [];

  inflector.uncountable('empresas');

  exports.default = {};
});