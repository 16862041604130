define('sirvo-web/controllers/popups/bs-simple-confirm', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    title: null,
    message: null,

    onSubmit() {},

    onCancel() {},

    actions: {
      onCancel() {
        return this.onCancel();
      },

      onSubmit() {
        return this.onSubmit();
      }
    }
  });
});