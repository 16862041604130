define('sirvo-web/controllers/login', ['exports', 'ember-changeset-validations/validators', 'ember-changeset', 'ember-concurrency', 'ember-local-storage'], function (exports, _validators, _emberChangeset, _emberConcurrency, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const loginValidations = {
    user: (0, _validators.validatePresence)(true),
    password: (0, _validators.validatePresence)(true),
    empresa: (0, _validators.validatePresence)(true)
  };

  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    toast: Ember.inject.service(),

    loader: Ember.inject.service(),

    request: Ember.inject.service(),

    empresaActual: (0, _emberLocalStorage.storageFor)('empresa'),

    errorMessage: null,

    init() {
      this._super(...arguments);
      this.set('loginForm', {});
      this.set('loginFormChangeset', new _emberChangeset.default(this.get('loginForm'), loginValidations));
    },

    getEmpresasTask: (0, _emberConcurrency.task)(function* () {
      yield this.get('loginFormChangeset').save();

      let { username, password } = this.get('loginForm');

      if (!username || !password) {
        return;
      }

      let empresas = yield this.request.post('consultar_empresas', { username, password }, { anonymous: true, quiet: true });

      this.set('empresas', empresas);
    }).drop(),

    actions: {
      async authenticate(model) {
        // Changeset save
        model.save();

        this.set('errorMessage', null);

        let { username, password, empresa } = this.loginForm;

        if (!username || !password) {
          return this.toast.warning('Ingrese sus credenciales.');
        }

        if (!empresa) {
          return this.toast.error('Seleccione una empresa para poder acceder.');
        }

        this.loader.isLoading();

        try {
          await this.session.authenticate('authenticator:jwt', {
            username: `${username}@${empresa.codempresa}`,
            password: password
          });

          this.empresaActual.set('content', empresa);
        } catch (error) {
          let errorMessage = error.json && (error.json.message || error.json.error) || error;

          this.set('errorMessage', Ember.typeOf(errorMessage) === 'string' ? errorMessage : 'Error inesperado');
        }

        this.loader.notLoading();

        // then is handle in route:application:sessionAuthenticated
      }
    }
  });
});