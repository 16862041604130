define('sirvo-web/initializers/current-company', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'currentCompany', 'service:current-company');
    application.inject('controller', 'currentCompany', 'service:current-company');
    application.inject('model', 'currentCompany', 'service:current-company');
  }

  exports.default = { initialize };
});