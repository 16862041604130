define('sirvo-web/routes/inventario/articulos/record', ['exports', 'servir-ember-utilities/routes/abstract-module-record', 'servir-ember-utilities/mixins/route/has-tabs', 'ember-changeset-validations/validators'], function (exports, _abstractModuleRecord, _hasTabs, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    modelName: 'kar-articulos',

    indexRoute: 'inventario.articulos',

    defaultTabSubRoute: 'datos-generales',

    relatedModelsToQuery: Ember.computed('company', function () {
      let modelsToQuery = [{
        name: 'tiposArticulos',
        modelName: 'kar-tipoarticulos',
        queryParams: {
          filter: {
            empresa: this.company.get('id')
          }
        }
      }];

      if (!this.isNew) {
        modelsToQuery.push({
          name: 'unidadesMedida',
          modelName: 'kar-tabla-medida',
          queryParams: {
            filter: {
              articulo: this.recordId
            }
          }
        });
      }

      return modelsToQuery;
    }),

    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)({ presence: true }),
        correlativo: (0, _validators.validatePresence)({ presence: true }),
        tipoArticulo: (0, _validators.validatePresence)({ presence: true }),
        codmedidaBase: (0, _validators.validatePresence)({ presence: true }),
        descripcion: (0, _validators.validatePresence)({ presence: true })
      };
    }),

    createRecordInstance() {
      return this.store.createRecord(this.get('modelName'), {
        empresa: this.company
      });
    },

    actions: {
      willTransition() {
        return false;
      }
    }
  });
});