define('sirvo-web/routes/dashboard/resultados', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    loader: Ember.inject.service(),

    request: Ember.inject.service(),

    empresaActual: (0, _emberLocalStorage.storageFor)('empresa'),

    model() {
      this.loader.isLoading();

      return Ember.RSVP.hash({
        empresas: this.request.get('accesos/directorio/empresas', {
          directorio: 'CONTA',
          codsys: 'CNT',
          codigo: '2010'
        })
      }).then(({ empresas }) => ({
        empresas: empresas.data.map(empresaData => new Ember.Object(empresaData))
      })).finally(() => this.loader.notLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        currentYear: new Date().getFullYear(),
        selectedEmpresa: null,
        resultados: [],
        resultadosData: [],
        selectedRow: null
      });

      let empresaActualId = this.empresaActual.get('content.id');
      if (empresaActualId) {
        let empresaObject = model.empresas.findBy('id', String(empresaActualId));

        if (empresaObject) {
          controller.set('selectedEmpresa', empresaObject);
          controller.empresaUpdated();
        }
      }
    }
  });
});